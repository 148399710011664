
















































import useSelectItems from '@/use/selectItems'
import { defineComponent, onMounted, reactive } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        firstName: '',
        lastName: '',
        degree: '',
        photo: '',
        description: ''
      })
    }
  },

  setup(_, { root }) {

    const state = reactive({
      items: [],
      degrees: [],
      empty: false,
      loading: false,
      loaded: false,
    })

    const fetchData = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      state.loading = true

      axiosInstance
        .get('degree')
        .then(({ data: { degrees } }) => {
          state.empty = false
          const result = degrees.map((degree: any, index: number) => ({ id: index, name: '', ...degree }))
          state.items = result.reduce((a: any, o: any) => (a.push(o.name), a), [])
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true
            state.items = []
          } else {
            console.log(error)
          }
        })
        .finally(() => {
          state.loaded = true
          state.loading = false
        })
    }

    onMounted(fetchData)

    const { languageItems } = useSelectItems({ root })

    return { state, languageItems }
  }
})
